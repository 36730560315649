import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';

import NavBar from '../../components/main/Navbar/Navbar';
import PathBar from '../../components/main/PathBar';
import ProfileBar from '../../components/main/ProfileBar';
import { TransferDomainForm } from '../../components/transferDomain/TransferDomainForm';

export function TransferDomain() {
    const location = useLocation();
    const { domainID, contactTypeID } = location.state;
    return (
        <>
            <NavBar activeElement="outreach" />
            <ProfileBar title="Transfer Domain" />
            <div className="bg-zedex-bg-gray pt-24 ml-20">
                <PathBar />
                <div className="Website-Outreach-Pool !-ml-0.5">
                    <Formik
                        initialValues={{
                            siteDA: 0,
                            siteDR: 0,
                            siteTraffic: 0,
                            priceAsked: 0,
                            finalPrice: 0,
                            specialPrice: 0,
                            linkInsertionPrice: 0,
                            POC: '',
                            status: 'Processing',
                            nicheID: [],
                        }}
                        onSubmit={() => {
                            //
                        }}
                    >
                        {() => (
                            <TransferDomainForm
                                domainID={domainID}
                                contactTypeID={contactTypeID}
                            />
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}

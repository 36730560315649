import { useFormikContext } from 'formik';
import {
    useGetAllContactTypeQuery,
    useGetAllNichesQuery,
    useGetAllServiceTypeQuery,
    useGetEmployeeUsingTokenQuery,
    useVerifyDomainLazyQuery,
} from '../../api/generated';

import { Combobox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { domainRegex } from '../../form/AddDomainSchema';
import Input from '../../shared/UI/Form/Input';

import { IoInformationCircleOutline } from 'react-icons/io5';
import Select from '../../shared/UI/Form/Select';
import Tooltip from '../../shared/UI/Tooltip/index';
import { AddDomainInitialValues } from '../../types/AddDomainInitialValues';
import { BlueButton } from '../main/BlueButton';
import { CloseButton } from '../main/CloseButton';
import { Exclamation } from '../main/Exclamation';
import { TickIcon } from '../main/VerifiedIcon';

function ComboBoxInput(props: {
    label: string;
    setFieldAttribute: string;
    attributeValue: string[];
    disabled: boolean;
}) {
    const formik = useFormikContext<AddDomainInitialValues>();
    const [query, setQuery] = useState('');
    const { data: options } = useGetAllNichesQuery();

    const filteredOptions =
        query === ''
            ? options?.getAllNiches
            : options?.getAllNiches?.filter((option) => {
                  return option?.toLowerCase().includes(query.toLowerCase());
              });
    return (
        <div className="w-full text-center rounded-lg">
            <Combobox
                disabled={props.disabled}
                value={props.attributeValue}
                onChange={(event) => {
                    if (event.length === 0) {
                        formik.setFieldValue(
                            props.setFieldAttribute,
                            options?.getAllNiches
                        );
                    } else {
                        formik.setFieldValue(props.setFieldAttribute, event);
                    }
                }}
                multiple
            >
                <Combobox.Input
                    className="w-full rounded-lg p-1 bg-blue-100 h-10 text-center"
                    placeholder={
                        props.attributeValue.length === 0
                            ? 'Search for Niches'
                            : props.attributeValue
                                  .map((option) => option)
                                  .join(', ')
                    }
                    onChange={(event) => setQuery(event.target.value)}
                    value={props.attributeValue
                        .map((option) => option)
                        .join(', ')}
                />

                <Combobox.Options className="bg-blue-50 mt-1 rounded-lg z-10 absolute overflow-auto min-w-[15rem] py-2 px-5">
                    {filteredOptions?.map(
                        (option) =>
                            option && (
                                <Combobox.Option
                                    key={option}
                                    value={option}
                                    className="hover:bg-blue-100"
                                >
                                    {props.attributeValue.includes(option) ? (
                                        <CheckIcon className="w-5 h-5 inline-block float-left border rounded-sm" />
                                    ) : (
                                        <div className="w-5 h-5 inline-block float-left fill-white border rounded-sm bg-white p-1" />
                                    )}
                                    <span>{option}</span>
                                </Combobox.Option>
                            )
                    )}
                </Combobox.Options>
            </Combobox>
        </div>
    );
}

const AddDomainInput = Input<AddDomainInitialValues>;
const AddDomainSelect = Select<AddDomainInitialValues>;

export function AddNewDomainForm() {
    const formik = useFormikContext<AddDomainInitialValues>();

    const { data: contactTypes } = useGetAllContactTypeQuery();

    const [verifiedDomainFun, { data: verifiedDomain }] =
        useVerifyDomainLazyQuery();

    const { data: serviceType } = useGetAllServiceTypeQuery();

    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();

    const linkInsertionID = serviceType?.getAllServiceType?.filter(
        (type) => type?.serviceType === 'LINK-INSERTION'
    )[0]?.id;

    // contact types option value

    const option = contactTypes?.getAllContactType?.map((el) => {
        return { label: el?.contactType, value: el?.id };
    });
    const optionServiceType = serviceType?.getAllServiceType?.map((el) => {
        return { label: el?.serviceType, value: el?.id };
    });

    const handelDomainVerify = async (
        event: React.FocusEvent<HTMLInputElement, Element>
    ) => {
        if (event.target.value !== '' && domainRegex.test(event.target.value)) {
            formik.setFieldValue('siteUrl', event.target.value);
            const verifyData = await verifiedDomainFun({
                variables: {
                    input: {
                        siteUrl: event.target.value,
                    },
                },
            });
            if (verifyData?.data?.verifyDomain?.verified) {
                formik.setFieldValue('verifiedDomain', true);
            }
        } else if (event.target.value !== '') {
            console.log('elseif is working');
            formik.setFieldValue('verifiedDomain', false);
            formik.setFieldValue('siteUrl', event.target.value);
        }
    };
    return (
        <div className="p-4">
            <h1 className="font-semibold text-lg inline-block space-x-2">
                <span className="float-left">Add New Domain</span>
                {verifiedDomain?.verifyDomain?.verified &&
                    formik.values?.verifiedDomain && (
                        <span className="float-left">
                            <TickIcon color="#6abd43" />
                            <span className="text-sm ml-1 p-2 rounded-xl bg-green-200 text-black">
                                Valid Domain
                            </span>
                        </span>
                    )}
                {verifiedDomain?.verifyDomain?.verified === false && (
                    <span className="float-left">
                        <Exclamation color="red" />
                        {verifiedDomain !== undefined ? (
                            <span className="text-sm ml-1 p-2 rounded-xl bg-rose-200 text-red">
                                The Domain already exists
                            </span>
                        ) : null}
                    </span>
                )}
            </h1>
            {verifiedDomain?.verifyDomain?.verified === false && (
                <div className="bg-rose-200 p-4 rounded-xl w-[50%] min-w-[20rem] text-center mt-3">
                    <div className="grid grid-cols-2">
                        <span>Contacted By: </span>
                        <span className="text-left">
                            {verifiedDomain?.verifyDomain?.contactedBy?.firstName?.concat(
                                ` ${verifiedDomain?.verifyDomain?.contactedBy?.lastName}`
                            )}
                        </span>
                    </div>
                    <div className="grid grid-cols-2">
                        <span>Price Asked:</span>
                        <span className="text-left">
                            {verifiedDomain?.verifyDomain?.priceAsked}
                        </span>
                    </div>
                    <div className="grid grid-cols-2">
                        <span>Final Price:</span>
                        <span className="text-left">
                            {verifiedDomain?.verifyDomain?.finalPrice}
                        </span>
                    </div>
                    <div className="grid grid-cols-2">
                        <span>Special Price:</span>
                        <span className="text-left">
                            {verifiedDomain?.verifyDomain?.specialPrice}
                        </span>
                    </div>
                    <div>
                        {verifiedDomain?.verifyDomain?.contactedBy?.id ===
                        LoggedInUser?.getEmployeeUsingToken?.id ? (
                            <BlueButton
                                to="/admin/outreach-sheet/domain"
                                state={{
                                    domainID:
                                        verifiedDomain?.verifyDomain?.domainID,
                                }}
                                label="View / Update Domain"
                            />
                        ) : (
                            <BlueButton
                                to="/domain-transfer"
                                state={{
                                    domainID:
                                        verifiedDomain?.verifyDomain?.domainID,
                                    contactTypeID:
                                        verifiedDomain?.verifyDomain
                                            ?.contactTypeID,
                                }}
                                label="Request Domain Transfer"
                            />
                        )}
                    </div>
                </div>
            )}
            <CloseButton
                redirectURL="/admin/outreach-sheet"
                className="float-right"
            />
            <div className=" grid grid-cols-3 my-4">
                <div className=" space-y-2 p-2">
                    <AddDomainInput
                        name="siteUrl"
                        isRequired
                        label="Site URL:"
                        placeholder="Eg. google.com"
                        onBlur={(event) => {
                            formik.setFieldTouched('siteUrl');
                            handelDomainVerify(event);
                        }}
                    />
                </div>
                <div className="space-y-2 p-2">
                    <AddDomainInput
                        name="ahrefTraffic"
                        isRequired
                        label="Ahref traffic:"
                    />
                </div>
                <div className="space-y-2 p-2">
                    <AddDomainSelect
                        name="contactType"
                        isRequired
                        label="Contact Type:"
                        options={option}
                    />
                </div>
                <div className="space-y-2 p-2">
                    <AddDomainInput
                        name="siteDA"
                        isRequired
                        label="Site DA:"
                        placeholder="Eg. 50"
                        type="number"
                        min={0}
                        max={100}
                        defaultValue={
                            verifiedDomain?.verifyDomain?.siteDA as number
                        }
                        disabled={
                            !formik.values.verifiedDomain ||
                            verifiedDomain?.verifyDomain?.siteDA !== null
                        }
                    />
                    <div className="absolute top-0 left-20">
                        <Tooltip label="Amount should be in dollar">
                            <IoInformationCircleOutline className=" font-bold w-5 h-5 " />
                        </Tooltip>
                    </div>
                </div>
                <div className="space-y-2 p-2">
                    <AddDomainInput
                        name="siteDR"
                        isRequired
                        label="Site DR:"
                        placeholder="Eg. 50"
                        type="number"
                        disabled={
                            !formik.values.verifiedDomain ||
                            verifiedDomain?.verifyDomain?.siteDR !== null
                        }
                    />
                    <div className="absolute top-0 left-20">
                        <Tooltip label="Amount should be in dollar">
                            <IoInformationCircleOutline className=" font-bold w-5 h-5 " />
                        </Tooltip>
                    </div>
                </div>
                <div className="space-y-2 p-2">
                    <AddDomainInput
                        name="siteTraffic"
                        isRequired
                        label="Semrush Traffic"
                        placeholder="100000"
                        type="number"
                        defaultValue={
                            verifiedDomain?.verifyDomain?.siteTraffic as number
                        }
                        disabled={
                            !formik.values.verifiedDomain ||
                            verifiedDomain?.verifyDomain?.siteTraffic !== null
                        }
                    />
                </div>
                <div className="space-y-2 p-2">
                    <AddDomainInput
                        name="priceAsked"
                        isRequired
                        label="Price Asked:"
                        placeholder="Eg. 50"
                        type="number"
                        disabled={!formik.values.verifiedDomain}
                    />
                    <div className="absolute top-0 left-28">
                        <Tooltip label="Amount should be in dollar">
                            <IoInformationCircleOutline className=" font-bold w-5 h-5 " />
                        </Tooltip>
                    </div>
                </div>
                <div className="space-y-2 p-2">
                    <AddDomainInput
                        name="finalPrice"
                        isRequired
                        label="Final Price:"
                        placeholder="Eg. 50"
                        type="number"
                        disabled={!formik.values.verifiedDomain}
                    />
                    <div className="absolute top-0 left-24">
                        <Tooltip label="Amount should be in dollar">
                            <IoInformationCircleOutline className=" font-bold w-5 h-5 " />
                        </Tooltip>
                    </div>
                </div>
                <div className="space-y-2 p-2">
                    <AddDomainInput
                        name="specialPrice"
                        isRequired
                        label="Special Price:"
                        placeholder="Eg. 50"
                        type="number"
                        disabled={!formik.values.verifiedDomain}
                    />
                    <div className="absolute top-0 left-28">
                        <Tooltip label="Amount should be in dollar">
                            <IoInformationCircleOutline className=" font-bold w-5 h-5 " />
                        </Tooltip>
                    </div>
                </div>
                <div className="col-span-3 space-y-2 p-2">
                    <label
                        className="block text-sm font-semibold text-slate-500"
                        htmlFor="nicheInput"
                    >
                        Niche:
                    </label>
                    <ComboBoxInput
                        label="Niche"
                        setFieldAttribute="nicheID"
                        attributeValue={formik.values.nicheID}
                        disabled={!formik.values.verifiedDomain}
                    />
                    {formik.errors.nicheID && (
                        <span className="text-red">
                            {formik.errors.nicheID}
                        </span>
                    )}
                </div>
                <div className="space-y-2 p-2">
                    <AddDomainInput
                        name="dateOfContact"
                        isRequired
                        label="Date of Contact:"
                        type="date"
                        disabled={!formik.values.verifiedDomain}
                    />
                </div>
                <div className="space-y-2 p-2">
                    <AddDomainInput
                        name="contactedByID"
                        isRequired
                        label="Contacted By:"
                        placeholder="Eg. 50"
                        type="text"
                        disabled
                        value={LoggedInUser?.getEmployeeUsingToken?.firstName?.concat(
                            ` ${LoggedInUser.getEmployeeUsingToken.lastName}`
                        )}
                    />
                </div>
                <div className="space-y-2 p-2">
                    <AddDomainInput
                        name="POC"
                        label="POC:"
                        isRequired
                        type="text"
                        placeholder="employee@email.com"
                        disabled={!formik.values.verifiedDomain}
                    />
                </div>
                <div className="space-y-2 p-2 col-span-3">
                    <AddDomainInput
                        name="siteContactDetails"
                        isRequired
                        label="Site Contact Details:"
                        type="text"
                        placeholder="Site Contact Email ID"
                        disabled={!formik.values.verifiedDomain}
                    />
                </div>
                <div className="space-y-2 p-2">
                    <AddDomainSelect
                        name="serviceType"
                        isRequired
                        label="Service Type:"
                        disable={!formik.values.verifiedDomain}
                        options={optionServiceType}
                    />
                </div>
                <div className="space-y-2 p-2">
                    <AddDomainInput
                        name="linkInsertionPrice"
                        isRequired
                        label="Link Insertion Price:"
                        type="number"
                        placeholder="Eg. 50"
                        disabled={
                            !(
                                formik.values.verifiedDomain &&
                                formik.values.serviceType === linkInsertionID
                            ) ||
                            verifiedDomain?.verifyDomain?.serviceTypeID ===
                                linkInsertionID
                        }
                    />
                </div>

                <div className="space-y-2 p-2">
                    <AddDomainSelect
                        name="status"
                        label="Status:"
                        isRequired
                        disable={!formik.values.verifiedDomain}
                        options={[
                            { label: 'Negotiating', value: 'Negotiating' },
                            { label: 'Done', value: 'Done' },
                        ]}
                    />
                </div>
                <div className="col-span-3 space-y-2 p-2">
                    <AddDomainInput
                        name="comments"
                        label="Comments:"
                        type="text"
                        placeholder="Add your comments here"
                        disabled={!formik.values.verifiedDomain}
                    />
                </div>
                <div className="col-span-3 mt-5 p-2 grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
                    <button
                        type="button"
                        onClick={() => {
                            window.location.reload();
                        }}
                        className="border-2 border-blue-600 text-blue-500 p-2 rounded-md"
                    >
                        Reset Form
                    </button>
                    <button
                        type="submit"
                        className={` text-white create-order align-middle p-1.5 w-48 ${
                            formik.values.verifiedDomain
                                ? `bg-zedex-button-blue hover:bg-zedex-button-blue-hover border-blue-600`
                                : `bg-slate-500 border-gray`
                        }`}
                        // disabled={!formik.values.verifiedDomain}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

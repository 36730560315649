import { Formik } from 'formik';
import { Maybe } from 'graphql/jsutils/Maybe';
import { number, object, string } from 'yup';
import { useAddSubPackageMutation } from '../../api/generated';
import Button from '../../shared/UI/Form/Button';
import Input from '../../shared/UI/Form/Input';

type NewPackageProps = {
    id: Maybe<number> | undefined;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: any;
};
type initialValueProp = {
    packageId: number | undefined | null;
    packageName: string;
    packageDescription: string;
    subPackageName: string;
    requiredDA: string;
    description: string;
    price: number;
};
const newPackedPropSchema = object({
    subPackageName: string().required('required'),
    packageDescription: string()
        .min(10, 'minimum 10 word required')
        .required('required'),
    requiredDA: string().required('required'),
    price: number()
        .required('required')
        .positive('Value must be a positive number'),
});

const NewSubPackageInput = Input<initialValueProp>;

export default function NewSubPackage({
    id,
    setShowModal,
    refetch,
}: NewPackageProps) {
    const initialValue: initialValueProp = {
        packageId: id,
        packageName: '',
        packageDescription: '',
        subPackageName: '',
        requiredDA: '',
        description: '',
        price: 0,
    };

    const [addSubPackage, { data }] = useAddSubPackageMutation();

    const handelNewSubPackage = async (values: initialValueProp) => {
        await addSubPackage({
            variables: {
                input: {
                    packageId: Number(values.packageId),
                    subPackageName: values.subPackageName,
                    packageDescription: values.packageDescription,
                    requiredDA: values.requiredDA,
                    price: values.price,
                },
            },
        });

        setShowModal(false);
        refetch();
    };

    return (
        <div className="bg-white rounded-lg">
            <div className="flex-1 h-auto max-w-md mx-auto  py-5 bg-blue rounded-lg shadow-xl">
                <Formik<initialValueProp>
                    initialValues={initialValue}
                    validationSchema={newPackedPropSchema}
                    onSubmit={handelNewSubPackage}
                >
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="rounded-lg">
                            <h1 className="text-xl text-center px-2 mb-4 font-bold text-black-400 ">
                                Add New SEO Sub Packages
                            </h1>
                            <div className="flex flex-col w-[80%] m-auto h-full gap-5 mt-8">
                                <NewSubPackageInput
                                    isRequired
                                    name="subPackageName"
                                    label="Sub Package Name:"
                                    type="text"
                                />
                                <NewSubPackageInput
                                    isRequired
                                    name="packageDescription"
                                    label=" Sub Package Description:"
                                    as="textarea"
                                />
                                <NewSubPackageInput
                                    isRequired
                                    name="requiredDA"
                                    label="Required DA:"
                                    type="text"
                                />
                                <NewSubPackageInput
                                    isRequired
                                    name="price"
                                    label="Price Asked:"
                                    type="number"
                                />
                            </div>
                            <div className="flex justify-center gap-5 mt-7">
                                <Button
                                    type="button"
                                    variant="outline"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </Button>
                                <Button type="submit" variant="primary">
                                    Save
                                </Button>
                            </div>
                            <br />
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

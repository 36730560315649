import { Link } from "react-router-dom";

export default function AddEmployeeButton() {
    return (
        <div className="text-center rounded-lg bg-zedex-button-blue text-white create-order  w-48   hover:bg-zedex-button-blue-hover">
            <Link to="/admin/add-employee">
                <div className=" flex items-center justify-center gap-2 py-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-5 h-5 inline-block"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                        />
                    </svg>
                    <span className="">Add Employee</span>
                </div>
            </Link>
        </div>
    );
}

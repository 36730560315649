import { Role, useGetEmployeeUsingTokenQuery } from '../../api/generated';
import { SearchBar } from '../main/SearchBar';
import AddEmployeeButton from './AddEmployeeButton';
import EmployeeTypeButton from './EmployeeTypeButton';
import TransferAccountButton from './TransferAccountButton';

export default function Search() {
    const { data: loggedInUser } = useGetEmployeeUsingTokenQuery();
    return (
        <div className="grid sm:grid-cols-1 lg:grid-cols-3 gap-5 items-end ml-4">
            <div>
                <SearchBar placeholder="Search by Employee Name or Email" />
            </div>
            <div className="flex gap-5">
                <EmployeeTypeButton />
                {loggedInUser?.getEmployeeUsingToken?.role === Role.Admin ? (
                    <AddEmployeeButton />
                ) : null}

            </div>
            <div>
                <TransferAccountButton />
            </div>
        </div>
    );
}

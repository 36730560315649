import { Formik } from 'formik';
import { Role, useGetEmployeeUsingTokenQuery } from '../../api/generated';
import { CreateOrderButton } from '../../components/clientOrdersList/CreateOrderButton';
import { Filter } from '../../components/clientOrdersList/Filters';
import { PredefinedFilters } from '../../components/clientOrdersList/PredefinedFilters';
import { TableAndGrid } from '../../components/clientOrdersList/TableGrid';
import { ToggleButton } from '../../components/clientOrdersList/ToggleSeoOrderManager';
import { FilterIcon } from '../../components/main/FilterIcon';
import NavBar from '../../components/main/Navbar/Navbar';
import { PaginationFooter } from '../../components/main/PaginationFooter';
import PathBar from '../../components/main/PathBar';
import ProfileBar from '../../components/main/ProfileBar';
import { SearchBar } from '../../components/main/SearchBar';
import { TableAndGridSeoEmp } from '../../components/seoClientOrdersList/TableGrid';

export function ClientOrders() {
    const { data: loggedInUser } = useGetEmployeeUsingTokenQuery();
    return (
        <>
            <NavBar activeElement="order" />
            <ProfileBar title="Client Orders" />
            <div className="bg-zedex-bg-gray pt-24 ml-20">
                <PathBar />
                <div className="Client-Order !-ml-0.5">
                    <Formik
                        initialValues={{
                            paginationRowCount: 15,
                            pageNum: 0,
                            totalPages: 1,
                            totalRows: 0,
                            sortBy: 'id',
                            sortType: 'asc',
                            statusFilter: [
                                'Done',
                                'Overdue',
                                'Processing',
                                'Cancelled',
                                'Issue-Raised',
                            ],
                            filterToggle: false,
                            toggleSeoOrderManager: true,
                            toggleView: true,
                            search: {
                                contains: '',
                            },
                        }}
                        onSubmit={() => {
                            //
                        }}
                    >
                        {(formik) => (
                            <>
                                <div className="flex gap-5 items-center">
                                    <SearchBar
                                        className="sm:col-span-3 lg:col-span-2 "
                                        placeholder="Search using Client Name, Order ID, Client Site URL or keyword"
                                    />
                                    <FilterIcon />
                                    {loggedInUser?.getEmployeeUsingToken
                                        ?.role !== Role.Admin ? (
                                        <span className="pt-2 lg:pt-4 sm:col-span-2 lg:col-span-1 flex self-center">
                                            <ToggleButton />
                                        </span>
                                    ) : null}
                                    <span className="flex self-center">
                                        {loggedInUser?.getEmployeeUsingToken
                                            ?.role === Role.Admin ||
                                            loggedInUser?.getEmployeeUsingToken
                                                ?.role === Role.Seoadmin ? (
                                            <CreateOrderButton />
                                        ) : (
                                            <CreateOrderButton />
                                        )}
                                    </span>
                                </div>
                                <Filter />
                                <PredefinedFilters />
                                {formik.values.toggleSeoOrderManager ? (
                                    <TableAndGrid />
                                ) : (
                                    <TableAndGridSeoEmp />
                                )}
                                <PaginationFooter />
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}

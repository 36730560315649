import { Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Role,
    useCreateOrderMutation,
    useGetAllOrderTypesQuery,
    useGetAllPackagesQuery,
    useGetEmployeeUsingTokenQuery,
    useGetEmployeeWithRoleQuery
} from '../../api/generated';
import CreateOrderForm from '../../components/createOrder/CreateOrderForm';
import { OrderInitialValues } from '../../components/createOrder/CreateOrderInitalValues';
import { CloseButton } from '../../components/main/CloseButton';
import NavBar from '../../components/main/Navbar/Navbar';
import PathBar from '../../components/main/PathBar';
import ProfileBar from '../../components/main/ProfileBar';
import { createOrderSchema } from '../../form/CreateOrderSchema';

export function CreateOrder() {
    const [createOrder] = useCreateOrderMutation();
    const { data: packages } = useGetAllPackagesQuery();
    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();
    const { data: orderTypes } = useGetAllOrderTypesQuery();
    const { data: cmsAdmins } = useGetEmployeeWithRoleQuery({
        variables: {
            input: {
                role: [Role.Cmsadmin],
            },
        },
    });
    const { data: seoOrderManagers } = useGetEmployeeWithRoleQuery({
        variables: {
            input: {
                role: [Role.Seoemployee],
            },
        },
    });
    const navigate = useNavigate();

    const handleCreateOrder = async (
        values: OrderInitialValues,
        formikHelpers: FormikHelpers<OrderInitialValues>
    ) => {
        try {
            const { data } = await createOrder({
                variables: {
                    input: {
                        clientBaseURL: values.clientBaseURL,
                        clientName: values.clientName,
                        selectedSite: values.selectedSite,
                        clientSiteURL: values.clientSiteUrlList,
                        mainOrder: {
                            id:
                                values.manualOrGeneratedID === 0
                                    ? values.id
                                    : (values.manualID as unknown as string),
                            ArticleCounts: values.clientSiteUrlList.length,
                            DAorDR: values.DAorDR,
                            orderTypeID:
                                values.orderTypeID as unknown as number,
                            clientSiteURL: values.clientSiteUrl.clientSiteURL,
                            orderCreatedByID:
                                values.orderCreatedByID as unknown as number,
                            orderDueDate:
                                values.orderDueDate.length === 0
                                    ? new Date().toISOString().substring(0, 10)
                                    : values.orderDueDate,
                            orderStartDate:
                                values.orderStartDate.length === 0
                                    ? new Date().toISOString().substring(0, 10)
                                    : values.orderStartDate,
                            orderStatus: values.orderStatus,
                            packageID: values.packageID as number,
                            paymentStatus: values.paymentStatus,
                            requiredDA:
                                values.DAorDR === 'DA'
                                    ? Number(values.requiredDA)
                                    : 0,
                            requiredDR:
                                values.DAorDR === 'DR'
                                    ? Number(values.requiredDR)
                                    : 0,
                            seoOrderManagerID:
                                values.seoOrderManagerID as unknown as number,
                        },
                        cmsOrder: {
                            cmsAdminID: values.cmsAdminID as unknown as number,
                            dateOfAssignmentCmsAdmin: new Date()
                                .toISOString()
                                .substring(0, 10),
                            instructionCmsAdmin: values.instructionCms,
                            orderStatus: values.orderStatus,
                        },
                        seoOrder: {
                            instructionsSeoEmployee:
                                values.instrucitonsSeoEmployee,
                            dateOfAssignment: new Date()
                                .toISOString()
                                .substring(0, 10),
                            orderStatus: values.orderStatus,
                            seoEmpID: values.seoOrderManagerID,
                            orderID:
                                values.manualOrGeneratedID === 0
                                    ? values.id
                                    : (values.manualID as unknown as string),
                        },
                    },
                },
            });

            if (data?.createOrder?.success) {
                toast.success('Order Created Successfully', {
                    position: 'top-right',
                });
                navigate('/admin/order-list');
            } else {
                toast.error(data?.createOrder?.message, {
                    position: 'top-right',
                });
            }
        } catch (err) {
            //
        }
    };
    return (
        <>
            <NavBar activeElement="order" />
            <ProfileBar title="Orders" />
            <div className="bg-zedex-bg-gray pt-24 ml-20">
                <PathBar />
                <div>
                    <Formik<OrderInitialValues>
                        initialValues={{
                            manualOrGeneratedID: 0,
                            manualID: '',
                            clientBaseURL: [],
                            selectedSite: [],
                            clientSiteUrlList: [],
                            clientSiteUrl: {
                                clientSiteURL: '',
                                keyword: '',
                                requiredDA: 0,
                            },
                            mainOrderClientSiteURL: '',
                            id: '',
                            clientName: '',
                            requiredDA: 1,
                            requiredDR: 1,
                            orderStartDate: new Date()
                                .toISOString()
                                .substring(0, 10),
                            orderDueDate: new Date()
                                .toISOString()
                                .substring(0, 10),
                            ArticleCounts: 0,
                            packageID:
                                (packages?.getAllPackages &&
                                    Number(packages?.getAllPackages[0]?.id)) ||
                                undefined,
                            cmsAdminID:
                                (cmsAdmins?.getEmployeeWithRole &&
                                    cmsAdmins.getEmployeeWithRole[0]?.id) ||
                                undefined,
                            orderCreatedByID:
                                LoggedInUser?.getEmployeeUsingToken?.id ||
                                undefined,
                            seoOrderManagerID:
                                (seoOrderManagers?.getEmployeeWithRole &&
                                    seoOrderManagers.getEmployeeWithRole[0]
                                        ?.id) ||
                                undefined,
                            instructionCms: '',
                            instrucitonsSeoEmployee: '',
                            paymentStatus: 'Processing',
                            orderStatus: 'Processing',
                            DAorDR: 'DA',
                            orderTypeID:
                                (orderTypes?.getAllOrderTypes &&
                                    orderTypes?.getAllOrderTypes[0]?.id) ||
                                undefined,
                        }}
                        validationSchema={createOrderSchema}
                        onSubmit={handleCreateOrder}
                    >
                        {(formik) => (
                            <form>
                                <div className="bg-white rounded-md !-ml-0.5">
                                    <CloseButton
                                        redirectURL="/admin/create-order"
                                        className="float-right mx-3 my-3"
                                    />
                                    <div className="text-xl font-semibold mx-4 my-2 mb-4 pt-4">
                                        Create Order
                                    </div>
                                    <CreateOrderForm />
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}

import { PlusIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Package } from '../../api/generated';
import '../../styles/PackageSheet.css';
import NewSubPackage from './NewSubPackage';

type NewSubPackageModalProp = {
    props: Package;
    refetch: any;
};

export default function NewSubPackageModal({
    props,
    refetch,
}: NewSubPackageModalProp) {
    const [showModal, setShowModal] = React.useState(false);
    const style1 =
        ' hh inline-block bg-transparent object-right hover:bg-blue float-right mr-3 text-blue-dark font-semibold rounded';
    const style2 =
        ' hh inline-block bg-transparent object-right pointer-events-none hover:bg-blue float-right mr-3 text-blue-dark font-semibold rounded';
    return (
        <>
            <button
                className={(props.isDisabled as boolean) ? style2 : style1}
                type="button"
                onClick={() =>
                    (props.isDisabled as boolean)
                        ? setShowModal(false)
                        : setShowModal(true)
                }
            >
                <PlusIcon className="h-6 w-6 float-right" />
            </button>

            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl ">
                            {/* content */}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/* header */}
                                {/* body */}
                                <div className="relative h-auto w-96 flex-auto">
                                    {/* <CloseButton2 className="float-right" /> */}
                                    <button
                                        type="button"
                                        className="absolute top-3 right-3 hover:text-red"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                    <NewSubPackage
                                        id={Number(props?.id)}
                                        setShowModal={setShowModal}
                                        refetch={refetch}
                                    />
                                </div>
                                {/* footer */}
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black" />
                </>
            ) : null}
        </>
    );
}

import 'react-toastify/dist/ReactToastify.css';

interface IButtonProps {
    buttonHeading: string;
    disabled?: boolean;
}

export default function ButtonComp(IButtonProp: IButtonProps) {
    return (
        <>
            <button
                type="submit"
                disabled={IButtonProp.disabled}
                className=" w-full px-6 py-2.5 bg-trev text-white font-medium text-xs leading-tight uppercase rounded  hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out shadow-lg"
            >
                {IButtonProp.buttonHeading}
            </button>

            <br />
        </>
    );
}

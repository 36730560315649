import { Formik } from 'formik';
import Navbar from '../../../components/main/Navbar/Navbar';
import { PaginationFooter } from '../../../components/main/PaginationFooter';
import PathBar from '../../../components/main/PathBar';
import ProfileBar from '../../../components/main/ProfileBar';
import {
    PredefinedFilters,
    ToggleView
} from '../../../components/transferDomainList/PredefinedFilters';
import { TableOrGrid } from '../../../components/transferDomainList/Table';

export default function TransferDomainList() {
    return (
        <>
            <Navbar activeElement="transferDomain" />
            <ProfileBar title="Domain Transfer" />
            <div className="bg-zedex-bg-gray pt-24 ml-20">
                <PathBar />
                <div className="Website-Outreach-Pool !-ml-0.5">
                    <Formik
                        initialValues={{
                            toggleView: true,
                            predefinedFilters: 'All',
                            paginationRowCount: 15,
                            pageNum: 0,
                            totalPages: 1,
                            totalRows: 0,
                            employeeID: 0,
                        }}
                        onSubmit={() => {
                            //
                        }}
                    >
                        {(formik) => (
                            <>
                                <div className="grid grid-cols-flow mt-4">
                                    <PredefinedFilters />
                                    <ToggleView />
                                </div>
                                <div className="mt-4">
                                    <TableOrGrid />
                                </div>
                                <PaginationFooter />
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}

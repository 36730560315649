/* eslint-disable react/no-unstable-nested-components */
import { useFormik, useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import type { Column, SortColumn } from 'react-data-grid';
import DataGrid, { SelectColumn, textEditor } from 'react-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import {
    Role,
    useEnableEmployeeMutation,
    usePaginatedEmployeeListQuery,
    useUpdateEmployeeMutation
} from '../../api/generated';
import { InitialValues } from '../../types/Initial';
import { CopyIcon } from '../main/CopyIcon';
import { signUpSchema } from '../SignUp';
import { DisableEmployeeButton } from './DisableEmployeeButton';

function EnableEmployeeButton(props: { enableRows: number[] }) {
    const [enableEmployee] = useEnableEmployeeMutation();
    const navigate = useNavigate();
    const formikss = useFormik({
        initialValues: {
            id: [0],
        },
        onSubmit: async () => {
            const { data } = await enableEmployee({
                variables: {
                    input: {
                        id: props.enableRows,
                    },
                },
            });
            if (data?.enableEmployee?.success) {
                window.location.reload();
                // navigate('/admin/emp-list');
            }
        },
    });

    return (
        <form onSubmit={formikss.handleSubmit}>
            <button
                id="id"
                name="id"
                type="submit"
                onChange={formikss.handleChange}
                className="bg-zedex-button-blue text-white create-order align-middle p-1.5 w-48 hover:bg-zedex-button-blue-hover"
            >
                <span className="">Enable Employee</span>
            </button>
        </form>
    );
}

function rowKeyGetter(row: Row) {
    return row.id;
}

interface Row {
    id: number;
    firstName: string;
    lastName: string;
    role: Role;
    email: string;
    phone: string;
    isActive: boolean;
}

export function NotWorking() {
    return (
        <div className="">
            <span className="bg-red py-1 px-3 rounded-md">Not Working</span>
            <br />
        </div>
    );
}

export function Working() {
    return (
        <div className="">
            <span className="bg-blue-300 py-1 px-3 rounded-md ">Working</span>
            <br />
        </div>
    );
}

export default function Table() {
    const formik = useFormikContext<InitialValues>();

    useEffect(() => {
        formik.setFieldValue('pageNum', 0);
    }, [formik.values.paginationRowCount]);

    const [updateEmployee] = useUpdateEmployeeMutation();

    const formi = useFormik({
        initialValues: {
            fname: '',
            lname: '',
            email: '',
            password: '',
            cpassword: '',
            phone: '',
            role: Role.Admin,
        },
        validationSchema: signUpSchema,
        onSubmit: () => {
            updateEmployee({
                variables: {
                    input: {
                        firstName: formi.values.fname,
                        lastName: formi.values.lname,
                        email: formi.values.email,
                        password: formi.values.password,
                        phone: formi.values.phone,
                        role: formi.values.role,
                    },
                },
            });
        },
    });

    const { data, loading } = usePaginatedEmployeeListQuery({
        variables: {
            input: {
                take: Number(formik.values.paginationRowCount),
                skip:
                    Number(formik.values.pageNum) *
                    Number(formik.values.paginationRowCount),
                sortBy: formik.values.sortBy,
                sortType: formik.values.sortType,
                search: {
                    ...formik.values.search,
                },
                roleFilters: formik.values.roleFilter,
            },
        },
    });

    useEffect(() => {
        formik.setFieldValue(
            'totalPages',
            data?.paginatedEmployeeList?.totalPages
        );
    }, [data?.paginatedEmployeeList?.totalPages]);

    useEffect(() => {
        formik.setFieldValue(
            'totalRows',
            data?.paginatedEmployeeList?.totalRows
        );
    }, [data?.paginatedEmployeeList?.totalRows]);

    const dataset = data?.paginatedEmployeeList?.emplo;

    const columnEmp = useMemo((): Column<Row>[] => {
        return [
            SelectColumn,
            {
                key: 'id',
                name: 'Id',
                width: 175,
                sortable: true,
                formatter: (props) => {
                    return (
                        <Link
                            to="/admin/emp-list/view-emp"
                            state={{ empId: props.row.id }}
                        >
                            <div className="flex flex-row text-right space-x-3">
                                <span className="basis-1/2">
                                    {props.row.id}
                                </span>
                                <span className="self-center">
                                    <CopyIcon
                                        text={String(props.row.firstName)}
                                    />
                                </span>
                            </div>
                        </Link>
                    );
                },
            },
            {
                key: 'firstName',
                name: 'First Name',
                width: 175,
                sortable: true,
                editor: textEditor,
            },
            {
                key: 'lastName',
                name: 'Last Name',
                width: 175,
                sortable: true,
                editor: textEditor,
            },
            {
                key: 'role',
                name: 'Employee Type',
                width: 175,
                sortable: true,
            },
            {
                key: 'email',
                name: 'Email',
                width: 175,
                sortable: true,
                editor: textEditor,
            },
            {
                key: 'phone',
                name: 'Phone No.',
                width: 175,
                sortable: true,
                editor: textEditor,
            },
            {
                key: 'isActive',
                name: 'Status',

                sortable: true,
                formatter(props) {
                    return props.row.isActive ? <Working /> : <NotWorking />;
                },
            },
        ];
    }, []);

    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
    const [selectedRows, setSelectedRows] = useState<Set<React.Key>>(
        () => new Set()
    );
    const rowArray = Array.from(selectedRows).map(Number);
    const c = true;
    return (
        <div>
            {selectedRows.size ? (
                <div className="grid sm:grid-cols-2 lg:grid-cols-7 content-center pt-5">
                    <DisableEmployeeButton disableRows={rowArray} />
                    <EnableEmployeeButton enableRows={rowArray} />
                </div>
            ) : (
                <>
                    <br />
                    <br />
                </>
            )}
            {data?.paginatedEmployeeList?.totalRows === 0 ? (
                <div
                    className="rounded-lg p-5 bg-blue-100 text-blue-500 text-center
                "
                >
                    No Data To be Displayed
                </div>
            ) : (
                <div className="text-center bg-transparent">
                    {loading ? (
                        <div
                            className="rounded-lg p-5 bg-blue-100 text-blue-500
                    "
                        >
                            Loading......
                        </div>
                    ) : (
                        <DataGrid<Row>
                            className={`rdg-light fill-grid ${formik.values.paginationRowCount >= 30
                                ? 'h-[940px]'
                                : formik.values.paginationRowCount >= 20
                                    ? 'h-[740px]'
                                    : formik.values.paginationRowCount >= 15
                                        ? 'h-[580px]'
                                        : formik.values.paginationRowCount >= 5
                                            ? 'h-[250px]'
                                            : 'h-[100px]'
                                }`}
                            rowKeyGetter={rowKeyGetter}
                            columns={columnEmp}
                            rows={dataset as Row[]}
                            // rowHeight={50}
                            selectedRows={selectedRows}
                            onSelectedRowsChange={(rows) =>
                                setSelectedRows(rows)
                            }
                            sortColumns={sortColumns}
                            onSortColumnsChange={setSortColumns}
                            defaultColumnOptions={{
                                sortable: true,
                                resizable: true,
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

import '../../styles/PathBar.css';

import { Link, useLocation } from 'react-router-dom';
import { Paths } from './PathBarPaths';


function PathBar(props: { appendPath?: string }) {
    const crumbs = useLocation().pathname.split('/');
    const currentURL = useLocation().pathname;

    return (
        <div className="bg-white py-4 px-4 mb-4 ">
            <div className="inline-block space-x-2">
                <HomeIcon color="#5570f1" />
                <span>
                    {crumbs.map((crumb, i) => {
                        const x = currentURL.split(crumb)[0].concat(crumb);
                        return crumb === 'admin' ||
                            crumb === 'seo' ||
                            crumb === 'cms' ||
                            crumb === 'employee' ? (
                            <span key={crumb} />
                        ) : (
                            <div
                                key={crumb}
                                className="inline-block space-x-2 ml-2 text-slate-400"
                            >
                                <a href={x}>{Paths.get(crumb)}</a>
                                {i === crumbs.length - 1 ? (
                                    <span>{props?.appendPath || null}</span>
                                ) : (
                                    <span>/</span>
                                )}
                            </div>
                        );
                    })}
                </span>
            </div>
        </div>
    );
}

function HomeIcon(props: { color: string }) {
    return (
        <Link to="/">

            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22 22"
                fill={props.color}
                className="w-5 h-5 inline-block"
            >
                <path
                    fillRule="evenodd"
                    d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                    clipRule="evenodd"
                />
            </svg>

        </Link>
    );
}

export default PathBar;

// <a key={crumbs} href={'/'.concat(crumbs)}>
//     {crumbs.concat(' / ')}
// </a>

import NavBar from '../../components/main/Navbar/Navbar';
import PathBar from '../../components/main/PathBar';
import ProfileBar from '../../components/main/ProfileBar';
import { Niche } from '../../components/niche/AddNicheForm';

export function CreateNiche() {
    return (
        <>
            <NavBar activeElement="outreach" />
            <ProfileBar title="Category" />
            <div className="bg-zedex-bg-gray pt-24 ml-20">
                <PathBar />
                <div className=" !-ml-0.5 bg-white py-[0.2] px-[1em] mr-[0.4em]">
                    <Niche />
                </div>
            </div>
        </>
    );
}

import { useFormikContext } from 'formik';
import { SearchFilter } from '../../api/generated';
import NewPackage from './NewAdminPackage';

export interface InitialValuesPackageList {
    search: SearchFilter;
    showModal: boolean;
}

export function NewPackageModal({ refetch }: any) {
    // const [showModal, setShowModal] = React.useState(false);
    const formikContext = useFormikContext<InitialValuesPackageList>();
    return (
        <>
            <button
                className="bg-blue-500 inline-block hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mx-6 mt-6 mb-6"
                type="button"
                onClick={() => formikContext.setFieldValue("showModal", true)}
            >
                + Add New Package
            </button>
            {formikContext.values.showModal ? (
                <>
                    <div className=" justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-4 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="relative h-96 w-96 flex-auto  object-center align-middle">
                                    <button
                                        type="button"
                                        className="absolute top-3 right-3 hover:text-red"
                                        onClick={() => formikContext.setFieldValue("showModal", false)}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="w-7 h-7"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                    <NewPackage refetch={refetch} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black" />
                </>
            ) : null}
        </>
    );
}

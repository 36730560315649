import '../styles/styles.css';

import * as Yup from 'yup';

import { Role, useAddEmployeeMutation } from '../api/generated';
import {
    EMAILS, PASSWORD_MATCH, PHONE_NUMBER,
    REQUIREDS,
    STRING_LONG,
    STRING_SHORT
} from './main/Schema';

import { Formik, FormikHelpers } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { nameRegex, passwordRegexExp } from '../shared/Regex/regex';
import Button from '../shared/UI/Form/Button';
import Input from '../shared/UI/Form/Input';
import Select from '../shared/UI/Form/Select';



export const signUpSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, STRING_SHORT)
        .max(50, STRING_LONG)
        .required(REQUIREDS)
        .matches(nameRegex, "Only Alphabet should be allow"),
    lastName: Yup.string()
        .min(2, STRING_SHORT)
        .max(50, STRING_LONG)
        .required(REQUIREDS)
        .matches(nameRegex, "Only Alphabet should be allow"),
    email: Yup.string().email(EMAILS).required(REQUIREDS),
    password: Yup.string()
        .required("Required")
        .min(8, "Password should be between 8-20 characters.")
        .max(20, "Password should be between 8-20 characters.")
        .matches(
            passwordRegexExp,
            "Password include numbers, special, upper & lowercase characters",
        ),
    cpassword: Yup.string()
        .oneOf([Yup.ref('password'), null], PASSWORD_MATCH)
        .required(REQUIREDS),
    phone: Yup.string()
        .matches(
            /^(?:(?:\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/,
            PHONE_NUMBER
        )
        .required(REQUIREDS),
});
type initialValueType = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    cpassword: string;
    phone: string;
    role: Role;
    isActive: boolean;
}
const SingUpInput = Input<initialValueType>
const SingUpSelect = Select<initialValueType>

export default function SignUp() {
    const navigate = useNavigate();

    const [addEmployee] = useAddEmployeeMutation();
    const initialValue: initialValueType = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        cpassword: '',
        phone: '',
        role: Role.Admin,
        isActive: true,

    }

    const handelAddEmployee = async (values: initialValueType,
        { resetForm }: FormikHelpers<initialValueType>) => {
        const { data } = await addEmployee({
            variables: {
                input: {
                    email: values.email,
                    firstName: values.firstName,
                    isActive: values.isActive,
                    lastName: values.lastName,
                    password: values.password,
                    phone: values.phone,
                    role: values.role
                },
            },
        });

        if (data?.addEmployee?.success) {
            resetForm()
            notify('Success');
            navigate('/admin/emp-list');
        } else {
            notify(data?.addEmployee?.message ? data?.addEmployee?.message : 'Error, Try Again');
        }
    }

    const notify = (statusMsg: string) => toast.error(statusMsg);


    return (
        <div
            className="background bg-cover bg-center w-full h-full"
            style={{
                backgroundImage: "url('/img/background.png')",
            }}
        >
            <div className="flex items-center min-h-screen bg-gray-50 rounded-md">
                <Formik<initialValueType> initialValues={initialValue}
                    validationSchema={signUpSchema}
                    onSubmit={handelAddEmployee}>
                    {({ handleSubmit }) => (
                        <div
                            className="flex-1 max-h-[90%] max-w-2xl mx-auto bg-blue rounded-lg shadow-xl"
                        >
                            <div className="flex flex-col md:flex-row rounded-md h-[90%]">
                                <div className="flex items-center h-32 md:h-auto md:w-1/3 bg-gradient-to-r from-trev to-indigo rounded-lg">
                                    <img
                                        className="object-fill object-center h-48 w-96 "
                                        src="/img/web1.png"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center p-3  px-8 md:w-2/3 rounded-lg">
                                    <div className="flex flex-col gap-2 w-full">
                                        <div className="flex justify-center">
                                            <img
                                                className="mb-3 logo  "
                                                src="/img/zedex.png"
                                                alt=""
                                            />
                                        </div>
                                        <h1 className="text-2xl font-bold text-trev text-center mb-3">
                                            Add Employee
                                        </h1>
                                        <div className="flex flex-wrap mx-2 mb-3 relative w-full justify-center  mt-1">
                                            <div className="w-full md:w-1/2  mb-6 md:mb-3 mt-1">
                                                <SingUpInput name='firstName' label='First Name'
                                                    type="text" placeholder='Enter First Name'
                                                />
                                            </div>

                                            <div className="w-full md:w-1/2 pl-1 mb-3 md:mb-0 mt-1 ">
                                                <SingUpInput name='lastName' label='Last Name'
                                                    type="text" placeholder='Enter Last Name'
                                                />

                                            </div>
                                            <br />
                                            <div className="inline-block relative w-full mb-3 mt-1">
                                                <SingUpInput name='email' label='Email'
                                                    type="text" placeholder='Enter Your Email'
                                                />
                                            </div>
                                            <br />
                                            <div className="inline-block relative w-full mb-3 mt-1">
                                                <SingUpInput name='password' label='Password'
                                                    type="password" placeholder='Enter Your Password'
                                                    isPass
                                                    className='pr-9'

                                                />
                                            </div>
                                            <br />
                                            <div className="inline-block relative w-full mb-3 mt-1">
                                                <SingUpInput name='cpassword' label='Confirm Password'
                                                    type="password" placeholder='Enter Confirm Password'
                                                    isPass
                                                    className='pr-9'
                                                />

                                            </div>
                                            <br />
                                            <div className="inline-block relative w-full mb-3 mt-1">
                                                <SingUpInput name='phone' label='Phone'
                                                    type="text" placeholder='Enter Phone Number'
                                                />

                                            </div>


                                            <div className="inline-block relative w-full mb-3 mt-1">
                                                <SingUpSelect name='role' label='Type' options={Object.values(Role).map(el => ({ label: el, value: el }))} />

                                            </div>

                                            <br />
                                            <div className="md:flex md:items-center inline-block relative w-full py-3">
                                                <div className="md:w-full flex justify-evenly">
                                                    <Button type='submit' variant='primary' onClick={() => handleSubmit()}>Add Employee</Button>
                                                    <Link to="/admin/emp-list"><Button type='button' variant='outline'>Cancel</Button></Link>
                                                    <ToastContainer />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    );
}

import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import {
    useAccountTransferMutation,
    useGetEmployeesQuery
} from '../../api/generated';
import '../../styles/styles.css';

function TransferIcon(props: { className: string }) {
    return (
        <svg
            width="51"
            height="51"
            viewBox="0 0 51 51"
            fill="none"
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M31 22.5625H20"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.875 26.6875L31 22.5625L26.875 18.4375"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31 35.625H25.5H20"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25.5 35.625V30.125"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M35.125 15H15.875C15.1156 15 14.5 15.6156 14.5 16.375V28.75C14.5 29.5094 15.1156 30.125 15.875 30.125H25.5H35.125C35.8844 30.125 36.5 29.5094 36.5 28.75V16.375C36.5 15.6156 35.8844 15 35.125 15Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="1"
                y="1"
                width="49"
                height="49"
                rx="24.5"
                strokeWidth="2"
            />
        </svg>
    );
}

export default function TransferAccountButton() {
    const { data } = useGetEmployeesQuery();
    const navigate = useNavigate();

    const [accountTransfer, { data: accountTransferResponse }] =
        useAccountTransferMutation();
    const notify = (statusMsg: string) => toast(statusMsg);

    const formik = useFormik({
        initialValues: {
            fromEmployeeID: 0,
            toEmployeeID: 0,
            confirmTransfer: false,
        },
        onSubmit: async () => {
            await accountTransfer({
                variables: {
                    input: {
                        fromEmployeeID: formik.values.fromEmployeeID,
                        toEmployeeID: formik.values.toEmployeeID,
                    },
                },
            });
            if (accountTransferResponse?.accountTransfer?.success) {
                notify('Account Transferred');
                setOpen(false);
            } else {
                notify(
                    accountTransferResponse?.accountTransfer?.message ||
                    'Account Transfer Unsuccessful, Error Occurred while transferring Account'
                );
                formik.setFieldValue('confirmTransfer', false);
            }
        },
    });
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <button
                onClick={() => setOpen(true)}
                type="button"
                className="bg-zedex-button-blue py-[11px] rounded-lg text-white create-order align-middle  w-48  hover:bg-zedex-button-blue-hover"
            >
                <span className="">Transfer Accounts</span>
            </button>
            {open ? (
                <div className="absolute">
                    {open ? (
                        <>
                            <ToastContainer />
                            <div className="hh justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                    <div className="border-2 border-trev m-10 p-5 bg-white rounded-xl space-y-4 w-[30rem]">
                                        <div className="space-x-8">
                                            <TransferIcon className="inline-block stroke-trev align-middle" />
                                            <div className="inline-block text-lg font-bold tracking-wide align-middle">
                                                Transfer Account Ownership
                                            </div>
                                            <button
                                                type="button"
                                                className="inline-block pl-12 text-lg"
                                                onClick={() => setOpen(false)}
                                            >
                                                x
                                            </button>
                                        </div>
                                        <div className="text-slate-500 tracking-wide text-justify text-sm">
                                            You can transfer profile, assigned
                                            task, project from this account to
                                            another account.
                                        </div>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="space-y-2 p-2">
                                                <label
                                                    className="block text-sm font-semibold text-slate-500"
                                                    htmlFor="TransferFromInput"
                                                >
                                                    Profile To Tranfer From:
                                                </label>
                                                <select
                                                    id="TransferFromInput"
                                                    className="border bg-white text-center border-slate-400 rounded-md w-full p-2"
                                                    required
                                                    onChange={(event) =>
                                                        formik.setFieldValue(
                                                            'fromEmployeeID',
                                                            Number(
                                                                event.target
                                                                    .value
                                                            )
                                                        )
                                                    }
                                                >
                                                    <option value={1}> </option>
                                                    {data?.getEmployees?.map(
                                                        (employee) => (
                                                            <option
                                                                key={
                                                                    employee?.id
                                                                }
                                                                value={
                                                                    employee?.id as number
                                                                }
                                                            >
                                                                {employee?.firstName?.concat(
                                                                    ` ${employee.lastName}`
                                                                )}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                            <div className="space-y-2 p-2 mb-4">
                                                <label
                                                    className="block text-sm font-semibold text-slate-500"
                                                    htmlFor="TransferToInput"
                                                >
                                                    Profile To Tranfer To:
                                                </label>
                                                <select
                                                    id="TransferToInput"
                                                    className="border bg-white text-center border-slate-400 rounded-md w-full p-2"
                                                    required
                                                    onChange={(event) =>
                                                        formik.setFieldValue(
                                                            'toEmployeeID',
                                                            Number(
                                                                event.target
                                                                    .value
                                                            )
                                                        )
                                                    }
                                                >
                                                    <option value={1}> </option>
                                                    {data?.getEmployees?.map(
                                                        (employee) => (
                                                            <option
                                                                key={
                                                                    employee?.id
                                                                }
                                                                value={
                                                                    employee?.id as number
                                                                }
                                                            >
                                                                {employee?.firstName?.concat(
                                                                    ` ${employee.lastName}`
                                                                )}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    formik.setFieldValue(
                                                        'confirmTransfer',
                                                        true
                                                    )
                                                }
                                                className="text-white w-[50%] p-3 mx-[23%] bg-zedex-button-blue hover:bg-zedex-button-blue-hover border-blue-600 rounded-lg"
                                            >
                                                Request To Transfer
                                            </button>
                                            {formik.values.confirmTransfer ? (
                                                <div className="mt-4 grid grid-cols-2 p-4">
                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            formik.setFieldValue(
                                                                'confirmTransfer',
                                                                false
                                                            )
                                                        }
                                                        className="text-white w-[50%] p-3 mx-[23%] bg-zedex-button-blue hover:bg-zedex-button-blue-hover border-blue-600 rounded-lg"
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="text-white w-[50%] p-3 mx-[23%] bg-zedex-button-blue hover:bg-zedex-button-blue-hover border-blue-600 rounded-lg"
                                                    >
                                                        Confirm
                                                    </button>
                                                    {!accountTransferResponse
                                                        ?.accountTransfer
                                                        ?.success ? (
                                                        <div
                                                            className="text-red text-xs
                        mt-3 col-span-2"
                                                        >
                                                            {
                                                                accountTransferResponse
                                                                    ?.accountTransfer
                                                                    ?.message
                                                            }
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : null}

                                            {/* <Message /> */}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            ) : null}
        </>
    );
}

import { Tab } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import DataGrid from 'react-data-grid';
import 'react-data-grid/lib/styles.css';
import { ToastContainer } from 'react-toastify';
import {
    Employee,
    Role,
    useGetAllOrderTypesQuery, useGetAllPackagesQuery,
    useGetEmployeeWithRoleQuery,
    useGetOrderGeneratedIdQuery
} from '../../api/generated';
import { OrderInitialValues } from './CreateOrderInitalValues';

export default function CreateOrderForm() {
    const formik = useFormikContext<OrderInitialValues>();
    const { data: packages } = useGetAllPackagesQuery();
    const { data: orderTypes } = useGetAllOrderTypesQuery();
    const { data: generatedOrderID } = useGetOrderGeneratedIdQuery({
        variables: {
            input: {
                orderTypeID: formik.values.orderTypeID,
                clientName: formik.values.clientName,
            },
        },
    });

    const { data: cmsAdmins } = useGetEmployeeWithRoleQuery({
        variables: {
            input: {
                role: [Role.Cmsadmin],
            },
        },
    });

    const { data: seoOrderManagers } = useGetEmployeeWithRoleQuery({
        variables: {
            input: {
                role: [Role.Seoemployee, Role.Seoadmin, Role.Admin],
            },
        },
    });

    useEffect(() => {
        formik.setFieldValue('id', generatedOrderID?.getOrderGeneratedID);
    }, [generatedOrderID?.getOrderGeneratedID]);

    useEffect(() => {
        if (formik.values.packageID === undefined) {
            formik.setFieldValue(
                'packageID',
                packages?.getAllPackages && packages?.getAllPackages[0]?.id
            );
        }
    }, [packages?.getAllPackages]);

    useEffect(() => {
        if (formik.values.orderTypeID === undefined) {
            formik.setFieldValue(
                'orderTypeID',
                orderTypes?.getAllOrderTypes &&
                orderTypes?.getAllOrderTypes[0]?.id
            );
        }
    }, [orderTypes?.getAllOrderTypes]);

    console.log("package", packages)



    return (
        <>
            <ToastContainer />
            <div className="grid lg:grid-cols-3 gap-y-5 gap-x-3 border px-5 py-3 mx-3">
                <div className="grid grid-cols-3 lg:col-span-2">
                    <label className="block text-gray-700 col-span-3 ml-3">
                        Order ID
                    </label>
                    <div className="col-span-3 grid grid-cols-2 gap-2 p-3">
                        <div className="flex items-center pl-4 space-x-4 ">
                            <input
                                type="radio"
                                checked={
                                    formik.values.manualOrGeneratedID === 0
                                }
                                onChange={() => {
                                    formik.setFieldValue(
                                        'manualOrGeneratedID',
                                        0
                                    );
                                    formik.setFieldValue('manualID', '');
                                    formik.setFieldValue(
                                        'id',
                                        generatedOrderID?.getOrderGeneratedID
                                    );
                                }}
                                value="0"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <div
                                className={`p-2 rounded-md w-full text-center ${formik.values.manualOrGeneratedID === 0
                                    ? 'bg-blue-100'
                                    : 'bg-slate-300'
                                    }`}
                            >
                                {`#${generatedOrderID?.getOrderGeneratedID}`}
                            </div>
                        </div>
                        <div className="flex items-center pl-4 dark:border-gray-700 space-x-4">
                            <input
                                type="radio"
                                checked={
                                    formik.values.manualOrGeneratedID === 1
                                }
                                onChange={() => {
                                    formik.setFieldValue(
                                        'manualOrGeneratedID',
                                        1
                                    );
                                }}
                                value="1"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <input
                                disabled={
                                    formik.values.manualOrGeneratedID !== 1
                                }
                                type="text"
                                required
                                onChange={(event) =>
                                    formik.setFieldValue(
                                        'manualID',
                                        event.target.value
                                    )
                                }
                                className="disabled:bg-slate-200 form-check-label inline-block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            />
                        </div>
                    </div>
                </div>
                <div className="">
                    <label className="ml-3 form-label block text-left text-gray-700 ">
                        Client Name
                    </label>
                    <div className="order col-span-3 p-3">
                        <div>
                            <input
                                required
                                onChange={(event) =>
                                    formik.setFieldValue(
                                        'clientName',
                                        event.target.value
                                    )
                                }
                                type="text"
                                className="form-check-label w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            />
                            <div className="text-red">
                                {formik.errors.clientName}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space-y-2">
                    <label
                        className="ml-3 block w-full text-gray-700"
                        htmlFor="orderStartDate"
                    >
                        Order Start Date:
                    </label>
                    <input
                        required
                        className="ml-3 border bg-white border-slate-400 block w-full p-2 rounded-md"
                        type="date"
                        name="orderStartDate"
                        id="orderStartDateInput"
                        placeholder="Order Start Date"
                        defaultValue={new Date().toISOString().substring(0, 10)}
                        onChange={(event) =>
                            formik.setFieldValue(
                                'orderStartDate',
                                event.target.value
                            )
                        }
                    />
                </div>
                <div className="space-y-2">
                    <label
                        className="ml-3 block w-full text-gray-700"
                        htmlFor="orderDueDate"
                    >
                        Order Due Date:
                    </label>
                    <input
                        className="block ml-3 w-full col-span-3 border bg-white border-slate-400 p-2 rounded-md"
                        type="date"
                        name="orderDueDate"
                        id="orderDueDateInput"
                        placeholder="Order Due Date"
                        defaultValue={new Date().toISOString().substring(0, 10)}
                        onChange={(event) =>
                            formik.setFieldValue(
                                'orderDueDate',
                                event.target.value
                            )
                        }
                        required
                    />
                </div>
                <br />
                <div className="space-y-2 grid grid-cols-3">
                    <label className="ml-3 block text-gray-700 col-span-3">
                        DA / DR Required
                    </label>
                    <div className="ml-3 ">
                        <select
                            className="p-2.5 border w-24 text-center bg-blue-100 rounded-md col-span-1"
                            onChange={(event) =>
                                formik.setFieldValue(
                                    'DAorDR',
                                    event.target.value
                                )
                            }
                        >
                            <option value="DA">DA</option>
                            <option value="DR">DR</option>
                        </select>
                    </div>
                    <div className="col-span-2">
                        <input
                            min={0}
                            max={100}
                            required
                            type="number"
                            onChange={(event) =>
                                formik.setFieldValue(
                                    'requiredDA',
                                    event.target.value
                                )
                            }
                            className="form-check-label w-full p-2.5 text-gray-700 bg-white border rounded-md focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        />
                        <div className="text-red">
                            {formik.errors.requiredDA}
                        </div>
                    </div>
                </div>
                <div className=" grid grid-cols-3">
                    <label className="block text-gray-700 col-span-3 mb-3 ml-3">
                        Order Type
                    </label>
                    <select
                        onChange={(event) =>
                            formik.setFieldValue(
                                'orderTypeID',
                                Number(event.target.value)
                            )
                        }
                        className="col-span-3 p-2.5 border text-center ml-3 bg-white rounded-md"
                    >
                        {orderTypes?.getAllOrderTypes?.map((orderType) => (
                            <option
                                key={orderType?.id}
                                value={orderType?.id as number}
                            >
                                {orderType?.orderType}
                            </option>
                        ))}
                    </select>
                </div>
                <div className=" grid grid-cols-3">
                    <label className="block text-gray-700 col-span-3 mb-3 ml-3">
                        Package
                    </label>
                    <select
                        onChange={(event) =>
                            formik.setFieldValue(
                                'packageID',
                                Number(event.target.value)
                            )
                        }
                        className="col-span-3 p-2.5 border text-center ml-3 bg-white rounded-md"
                    >
                        {packages?.getAllPackages?.map((pack) => (
                            <option key={pack?.id} value={pack?.id as number}>
                                {pack?.packageName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="lg:col-span-3">
                    <AddClientSiteURLInput />
                </div>
                <div className="border border-slate-400 rounded-lg p-5 lg:col-span-3">
                    <SelectedSitesTab />
                </div>
                <div className="border border-slate-400 rounded-lg p-5 lg:col-span-3">
                    <InstructionTab />
                </div>
                <div>
                    <AssignTaskToSeoOrderManager
                        seoOrderManagers={
                            seoOrderManagers?.getEmployeeWithRole as Employee[]
                        }
                    />
                </div>
                <div>
                    <AssignTaskToCMSAdmin
                        cmsEmployee={
                            cmsAdmins?.getEmployeeWithRole as Employee[]
                        }
                    />
                </div>

                <div className="grid grid-cols-2 gap-6">
                    <button
                        type="button"
                        onClick={() => formik.submitForm()}
                        className="px-6 py-2 rounded-md bg-zedex-button-blue hover:bg-zedex-button-blue-hover text-white"
                    >
                        Save
                    </button>
                    <button
                        type="button"
                        onClick={() => window.location.reload()}
                        className="px-6 py-2 rounded-md bg-zedex-button-blue hover:bg-zedex-button-blue-hover text-white"
                    >
                        Reset
                    </button>
                </div>
            </div>
        </>
    );
}

interface AddClientSiteURLTableRow {
    id: number;
    clientSiteURL: string;
    keyword: string;
    requiredDA: number;
    remove: any;
}

function AddClientSiteURLInput() {
    const formik = useFormikContext<OrderInitialValues>();
    const columns = [
        {
            key: 'id',
            name: 'Sr. No',
        },
        {
            key: 'clientSiteURL',
            name: 'Client Site URL',
        },
        {
            key: 'keyword',
            name: 'Keyword',
        },
        {
            key: 'requiredDA',
            name: 'Required DA / DR',
        },
        {
            key: 'remove',
            name: ' ',
        },
    ];

    const rows: AddClientSiteURLTableRow[] = [];
    formik.values.clientSiteUrlList.forEach((clientStieUrlRow, i) => {
        rows.push({
            id: i + 1,
            clientSiteURL: clientStieUrlRow.clientSiteURL,
            keyword: clientStieUrlRow.keyword,
            requiredDA:
                clientStieUrlRow.requiredDA !== 0
                    ? clientStieUrlRow.requiredDA
                    : formik.values.requiredDA,
            remove: (
                <button
                    className="w-full flex justify-center p-1"
                    type="button"
                    onClick={() => {
                        const clientSiteUrlSplice =
                            formik.values.clientSiteUrlList;
                        clientSiteUrlSplice.splice(i, 1);
                        formik.setFieldValue(
                            'clientSiteUrlList',
                            clientSiteUrlSplice
                        );
                    }}
                >
                    <XCircleIcon className="w-5 h-5 fill-red" />
                </button>
            ),
        });
    });

    return (
        <div className="grid lg:grid-cols-4 border border-slate-400 rounded-md p-5">
            <label className="lg:col-span-4 mb-3 block ml-3 text-md">
                Client Site URL
                {formik.errors.clientSiteUrlList ? (
                    <div className="text-red">
                        {formik.errors.clientSiteUrlList as string}
                    </div>
                ) : null}
            </label>

            <div className="align-middle px-2 py-2 text-left">
                <label className="form-label block mb-2 text-gray-700">
                    Client Site URL
                </label>
                <input
                    name="clientSiteUrl"
                    type="url"
                    className="form-control sm:w-full block px-3 py-1.5 text-base font-normal text-gray-700 bg-white border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Client Site URL"
                    onChange={(event) =>
                        formik.setFieldValue(
                            'clientSiteUrl.clientSiteURL',
                            event.target.value
                        )
                    }
                />
                <div className="text-red">
                    {formik.errors.clientSiteUrl?.clientSiteURL}
                </div>
            </div>
            <div className="align-middle px-2 py-2 text-left">
                <label className="form-label block mb-2 text-gray-700">
                    Client Keyword
                </label>
                <input
                    type="text"
                    required
                    className="form-control sm:w-full block px-3 py-1.5 text-base font-normal text-gray-700 bg-white border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="clientKeyword"
                    placeholder="eg. Equipment"
                    onChange={(event) =>
                        formik.setFieldValue(
                            'clientSiteUrl.keyword',
                            event.target.value
                        )
                    }
                />
                <div className="text-red">
                    {formik.errors.clientSiteUrl?.keyword}
                </div>
            </div>
            <div className="align-middle px-2 py-2 text-left">
                <label className="form-label block mb-2 text-gray-700">
                    {`${formik.values.DAorDR} Required`}
                </label>
                <input
                    type="number"
                    className="form-control sm:w-full block px-3 py-1.5 text-base font-normal text-gray-700 bg-white border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="articleCount"
                    placeholder="Eg. 50"
                    min={0}
                    max={100}
                    defaultValue={0}
                    onChange={(event) =>
                        formik.setFieldValue(
                            'clientSiteUrl.requiredDA',
                            Number(event.target.value) === 0
                                ? Number(formik.values.requiredDA)
                                : Number(event.target.value)
                        )
                    }
                />
            </div>
            <div className="align-middle px-2 py-2">
                <button
                    className="lg:mt-[1.9rem] bg-zedex-button-blue text-white font-bold text-md form-control sm:w-32 block px-3 py-1.5 rounded-md hover:bg-blue-600 disabled:bg-slate-400 disabled:text-white"
                    type="button"
                    // disabled={
                    //     formik.values.ArticleCounts ===
                    //     formik.values.clientSiteUrlList.length
                    // }
                    onClick={() => {
                        const baseURL =
                            formik.values.clientSiteUrl.clientSiteURL.split(
                                '/'
                            );

                        formik.setFieldValue('clientBaseURL', [
                            `${baseURL[0]}//${baseURL[2]}`,
                            ...formik.values.clientBaseURL,
                        ]);

                        formik.setFieldValue(`clientSiteUrlList`, [
                            formik.values.clientSiteUrl,
                            ...formik.values.clientSiteUrlList,
                        ]);
                    }}
                >
                    + Add
                </button>
            </div>
            <div className="lg:col-span-4 text-center">
                {rows.length === 0 ? (
                    <div className="rounded-lg p-5 bg-blue-100 text-blue-500 text-center">
                        No Client Site URLs Added
                    </div>
                ) : (
                    <DataGrid
                        className="rdg-light border rounded-md -z-10"
                        columns={columns}
                        rows={rows}
                        defaultColumnOptions={{
                            sortable: true,
                            minWidth: 269.5,
                        }}
                    />
                )}
            </div>
        </div>
    );
}

function SelectedSitesTab() {
    const formik = useFormikContext<OrderInitialValues>();
    const [manualOrBulk, setManualOrBulk] = useState(true);
    const [csvData, setCsvData] = useState([]);

    const handleFileRead = (e: any) => {
        const content = e.target?.result;
        const rows = content
            .trim()
            .split('\r\n')
            .map((site: string) => site.trim());
        formik.setFieldValue('selectedSite', [
            ...rows,
            ...formik.values.selectedSite,
        ]);
    };

    const handleFileChosen = (file: File) => {
        const reader = new FileReader();
        reader.onloadend = handleFileRead;
        reader.readAsText(file);
    };

    return (
        <div>
            <label className="mb-3 block ml-3 text-md">Selected Sites</label>
            <Tab.Group>
                <Tab.List className="space-x-1 ml-2">
                    <Tab
                        className={`w-48 rounded-t-lg p-2 bg-blue-100 hover:bg-blue-200 ${!manualOrBulk ? 'bg-slate-100' : ''
                            }`}
                        onClick={() => setManualOrBulk(false)}
                    >
                        Add Manual Entries
                    </Tab>
                    <Tab
                        className={`w-48 rounded-t-lg p-2 bg-blue-100 hover:bg-blue-200 ${manualOrBulk ? 'bg-slate-100' : ''
                            }`}
                        onClick={() => setManualOrBulk(true)}
                    >
                        Attached Bulk Entries
                    </Tab>
                </Tab.List>
                <Tab.Panels className="border-[5px] rounded-xl border-blue-100 ">
                    <Tab.Panel>
                        <textarea
                            value={formik.values.selectedSite.join('\r')}
                            onChange={(event) => {
                                const { length } = event.target.value;
                                const { value } = event.target;
                                const selectedSitesAsString =
                                    value.charAt(length - 1) === ','
                                        ? event.target.value.substring(
                                            0,
                                            length - 1
                                        )
                                        : value;
                                formik.setFieldValue(
                                    'selectedSite',
                                    selectedSitesAsString
                                        .split('\n')
                                        .map((site) => site.trim())
                                );
                            }}
                            placeholder="Selected Sites for the Order"
                            className="w-full h-48 rounded-lg p-3"
                        />
                    </Tab.Panel>
                    <Tab.Panel>
                        <div className="flex items-center justify-center w-full">
                            <label
                                htmlFor="dropzone-file"
                                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                            >
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg
                                        aria-hidden="true"
                                        className="w-10 h-10 mb-3 text-gray-400"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                        />
                                    </svg>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                        <span className="font-semibold">
                                            Click to upload
                                        </span>{' '}
                                        or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                        CSV
                                    </p>
                                </div>
                                <input
                                    id="dropzone-file"
                                    type="file"
                                    className="hidden"
                                    onChange={(event) =>
                                        handleFileChosen(
                                            event.target.files! &&
                                            event.target.files[0]
                                        )
                                    }
                                />
                            </label>
                        </div>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
}

function InstructionTab() {
    const formik = useFormikContext<OrderInitialValues>();
    const [SeoOrCMS, setSeoOrCms] = useState(true);
    return (
        <div>
            <label className="mb-3 block ml-3 text-md">Instructions</label>
            <Tab.Group>
                <Tab.List className="space-x-1 ml-2">
                    <Tab
                        className={`w-60 rounded-t-lg p-2 bg-blue-100 hover:bg-blue-200 ${!SeoOrCMS ? 'bg-slate-100' : ''
                            }`}
                        onClick={() => setSeoOrCms(false)}
                    >
                        Instruction to SEO Employee
                    </Tab>
                    <Tab
                        className={`w-60 rounded-t-lg p-2 bg-blue-100 hover:bg-blue-200 ${SeoOrCMS ? 'bg-slate-100' : ''
                            }`}
                        onClick={() => setSeoOrCms(true)}
                    >
                        Instruction to CMS Employee
                    </Tab>
                </Tab.List>
                <Tab.Panels className="border-[5px] rounded-xl border-blue-100">
                    <Tab.Panel>
                        <textarea
                            onChange={(event) =>
                                formik.setFieldValue(
                                    'instrucitonsSeoEmployee',
                                    event.target.value
                                )
                            }
                            placeholder="Instruction for SEO Employee"
                            className="w-full h-48 rounded-lg p-3"
                        />
                    </Tab.Panel>
                    <Tab.Panel>
                        <textarea
                            onChange={(event) =>
                                formik.setFieldValue(
                                    'instructionCms',
                                    event.target.value
                                )
                            }
                            placeholder="Instruction for CMS Employee"
                            className="w-full h-48 rounded-lg p-3"
                        />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
}

function AssignTaskToSeoOrderManager(props: {
    seoOrderManagers: Employee[] | undefined;
}) {
    const formik = useFormikContext<OrderInitialValues>();

    return (
        <div className="flex justify-center ">
            <label className="text-center flex self-center w-40">
                SEO Order Manager:
            </label>
            <select
                onChange={(event) =>
                    formik.setFieldValue(
                        'seoOrderManagerID',
                        Number(event.target.value)
                    )
                }
                className="text-center block px-6 py-2 rounded-md w-60"
            >
                <option value={undefined}>None</option>
                {props?.seoOrderManagers?.map((employee) => (
                    <option key={employee?.id} value={employee?.id as number}>
                        {`${employee?.firstName} ${employee?.lastName}`}
                    </option>
                ))}
            </select>
        </div>
    );
}

function AssignTaskToCMSAdmin(props: { cmsEmployee: Employee[] }) {
    const formik = useFormikContext<OrderInitialValues>();
    return (
        <div className="flex justify-center ">
            <label className="text-center flex self-center w-40">
                CMS Admin:
            </label>
            <select
                onChange={(event) =>
                    formik.setFieldValue(
                        'cmsAdminID',
                        Number(event.target.value)
                    )
                }
                className="text-center block px-6 py-2 rounded-md w-60"
            >
                <option selected value={undefined}>
                    None
                </option>
                {props.cmsEmployee?.map((employee) => (
                    <option key={employee?.id} value={employee?.id as number}>
                        {`${employee?.firstName} ${employee?.lastName}`}
                    </option>
                ))}
            </select>
        </div>
    );
}

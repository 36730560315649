import { Formik } from 'formik';
import { PredefinedFilters } from '../../../components/clientOrdersList/PredefinedFilters';
import { Filter } from '../../../components/cmsEmpClientOrderList/Filters';
import { TableAndGrid } from '../../../components/cmsEmpClientOrderList/TableGrid';
import { FilterIcon } from '../../../components/main/FilterIcon';
import NavBar from '../../../components/main/Navbar/Navbar';
import { PaginationFooter } from '../../../components/main/PaginationFooter';
import PathBar from '../../../components/main/PathBar';
import ProfileBar from '../../../components/main/ProfileBar';
import { SearchBar } from '../../../components/main/SearchBar';

export function CmsClientOrders() {
    return (
        <>
            <NavBar activeElement="order" />
            <ProfileBar title="Client Orders" />
            <div className="bg-zedex-bg-gray pt-24 ml-20">
                <PathBar />
                <div className="Client-Order !-ml-0.5">
                    <Formik
                        initialValues={{
                            paginationRowCount: 15,
                            pageNum: 0,
                            totalPages: 1,
                            totalRows: 0,
                            sortBy: 'id',
                            sortType: 'asc',
                            statusFilter: [
                                'Done',
                                'Overdue',
                                'Processing',
                                'Cancelled',
                                'Issue-Raised',
                            ],
                            filterToggle: false,
                            toggleView: true,
                            search: {
                                contains: '',
                            },
                        }}
                        onSubmit={() => {
                            //
                        }}
                    >
                        <>
                            <div className="mt-3">
                                <SearchBar placeholder="Search using Client Name, Order ID, Client Site URL or keyword" />
                                <FilterIcon />
                            </div>
                            <Filter />
                            <PredefinedFilters />
                            <TableAndGrid />
                            <PaginationFooter />
                        </>
                    </Formik>
                </div>
            </div>
        </>
    );
}

import { Formik } from 'formik';
import { RxCross1 } from "react-icons/rx";
import { useNavigate, useParams } from 'react-router-dom';
import { number, object, string } from 'yup';
import {
    useGetSubPackageQuery,
    useUpdateSubPackageMutation
} from '../../api/generated';
import Button from '../../shared/UI/Form/Button';
import Input from '../../shared/UI/Form/Input';
import NavBar from '../main/Navbar/Navbar';
import ProfileBar from '../main/ProfileBar';



type initialValueProp = {
    id?: number | undefined | null;
    packageName?: string | undefined | null;
    packageDescription: string | undefined | null;
    subPackageName: string | undefined | null;
    requiredDA: string | undefined | null;
    description?: string | undefined | null;
    price: number | undefined | null,
}

const updatePackedPropSchema = object({
    subPackageName: string().required("required"),
    packageDescription: string().min(10, "minimum 10 word required").required("required"),
    requiredDA: string().required("required"),
    price: number().required("required").positive("Value must be a positive number")
})
const NewSubPackageInput = Input<initialValueProp>
export default function UpdateSubPackage() {
    const [updateSubPackage, { data }] = useUpdateSubPackageMutation();

    const navigate = useNavigate()

    const { type } = useParams();

    const { data: subPackages, loading } = useGetSubPackageQuery({
        variables: {
            getSubPackageId: Number(type),
        },
    });

    const InitialValue = {
        id: subPackages?.getSubPackage?.id as number,
        packageDescription: subPackages?.getSubPackage?.packageDescription,
        subPackageName: subPackages?.getSubPackage?.subPackageName,
        requiredDA: subPackages?.getSubPackage?.requiredDA,
        price: subPackages?.getSubPackage?.price,

    }
    // const formik = useFormik({
    //     initialValues: {
    //         id: subPackages?.getSubPackage?.id as number,
    //         packageName: '',
    //         packageDescription: '',
    //         subPackageName: '',
    //         requiredDA: '',
    //         description: '',
    //         price: 0,
    //     },
    //     onSubmit: () => {
    //         updateSubPackage({
    //             variables: {
    //                 input: {
    //                     id: formik.values.id,
    //                     // packageName: formik.values.packageName,
    //                     packageDescription: formik.values.packageDescription,
    //                     subPackageName: formik.values.subPackageName,
    //                     requiredDA: formik.values.requiredDA,
    //                     price: formik.values.price,
    //                     // description: formik.values.description,
    //                 },
    //             },
    //         });
    //         window.location.href = '/admin/packages';
    //     },
    // });
    const handelUpdateSubmit = async (values: initialValueProp) => {
        updateSubPackage({
            variables: {
                input: {
                    id: values.id!,
                    packageDescription: values.packageDescription,
                    subPackageName: values.subPackageName,
                    requiredDA: values.requiredDA,
                    price: values.price,

                },
            },
        });
        window.location.href = '/admin/packages';
    }

    if (!loading) {
        return (
            <div>
                <NavBar activeElement="inbox" />
                <ProfileBar title="Packages" />
                <div className="bg-[#f4f5fa] pt-24 ">

                    <div className="flex-1 h-auto max-w-md mx-auto bg-blue rounded-lg shadow-xl bg-white   ">

                        <Formik<initialValueProp> initialValues={InitialValue}
                            validationSchema={updatePackedPropSchema}
                            onSubmit={handelUpdateSubmit}>{({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>

                                    <div className="flex flex-col sm:flex-row rounded-md object-center justify-center py-5 ">
                                        <div className="mb-4  relative">
                                            <label className="inline relative text-xl font-medium text-center text-black-900 dark:text-gray-300 px-3 py-3 mt-4 ">
                                                Update SEO Sub Package
                                            </label>
                                            <br />
                                            <div className='absolute top-1 -right-7 group' >
                                                <RxCross1 className='group-hover:text-red font-bold' onClick={() => navigate("/admin/packages")} />
                                            </div>

                                            <div className='flex flex-col w-[100%] m-auto h-full gap-5 mt-8'>
                                                <NewSubPackageInput isRequired name='subPackageName' label='Sub Package Name:' type="text" />
                                                <NewSubPackageInput isRequired name='packageDescription' label=' Sub Package Description:' as='textarea' />

                                                <NewSubPackageInput isRequired name='requiredDA' label='Required DA:' type="text" />
                                                <NewSubPackageInput isRequired name='price' label='Price Asked:' type="number" />

                                                <div className='flex justify-center gap-5 mt-2 '>
                                                    <Button type='button' variant='outline' onClick={() => navigate("/admin/packages")}
                                                    >Cancel</Button>
                                                    <Button type='submit' variant='primary'>Save</Button>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </form>
                            )}</Formik>
                    </div>

                </div>
            </div>
        );
    }
    return null;
}

import { Formik, FormikHelpers } from 'formik';

import * as Yup from 'yup';
import { useLoginEmployeeMutation } from '../../api/generated';
import ButtonComp from '../../components/main/Button';
import FormComponent from '../../components/main/FormComponent';
import { EMAILS, REQUIREDS } from '../../components/main/Schema';
import { TOKEN } from '../../constants/storageKeys';

import { toast, ToastContainer } from 'react-toastify';
import Input from '../../shared/UI/Form/Input';
import '../../styles/style.css';

const loginSchema = Yup.object().shape({
    email: Yup.string().email(EMAILS).required(REQUIREDS),
    password: Yup.string().required(REQUIREDS),
});

type initialValueProp = {
    email: string;
    password: string;
}

const initialValue: initialValueProp = {
    email: "",
    password: ""
}
const LoginInput = Input<initialValueProp>
export default function Login() {
    const [loginEmployee, { loading: loginLoading }] = useLoginEmployeeMutation();

    const handelLogin = async (values: initialValueProp,
        { resetForm, }: FormikHelpers<initialValueProp>) => {

        const { data } = await loginEmployee({
            variables: {
                input: values
            },
        });

        if (data?.loginEmployee?.success) {
            localStorage.setItem(
                TOKEN,
                data?.loginEmployee?.token as string
            );
            toast.success("successfully login")

            resetForm()
            window.location.replace("/")

        } else {
            notify(data?.loginEmployee?.message ? data.loginEmployee.message : 'Wrong Credentials, Try Again');
        }
    }
    const notify = (statusMsg: string) => toast.error(statusMsg);

    return (
        <div
            className="Background bg-cover bg-center"
            style={{
                backgroundImage: "url('/img/background.png')",
            }}
        >
            <div className="  flex  min-h-screen justify-center items-center  py-8 px-2 sm:px-4 lg:px-6 ">
                <div className="flex flex-col  md:flex-row md:max-w-xl rounded-lg bg-gray shadow-2xl">
                    <div className="flex items-center h-32 md:h-auto md:w-1/2 bg-gradient-to-r from-trev to-indigo rounded-lg">
                        <img src="/img/des.png" alt="" />
                    </div>
                    <div className="p-6 flex flex-col justify-start">
                        <Formik<initialValueProp>
                            initialValues={initialValue}
                            validationSchema={loginSchema}
                            onSubmit={handelLogin}>{({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <img
                                        className="mb-3 !mt-0 logo  "
                                        src="/img/zedex.png"
                                        alt=""
                                    />
                                    <h1 className="text-2xl mt-6 font-bold text-trev text-center mb-3">
                                        Login Page
                                    </h1>
                                    <div className="form-group mb-6  ">
                                        <LoginInput name='email' type="text " label='Email' placeholder='Enter your email' isRequired />


                                    </div>
                                    <div className="form-group relative mb-6">
                                        <LoginInput type="password" name='password' label='Password' placeholder='Enter your password' isPass isRequired />

                                    </div>
                                    <FormComponent />
                                    <ButtonComp buttonHeading="Login" disabled={loginLoading} />
                                    <ToastContainer />
                                </form>
                            )}</Formik>
                    </div>
                </div>
            </div>
        </div>
    ); //
}

import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useFormikContext } from 'formik';
import { EmployeeTypeInitialValues } from '../../types/EmployeeTypeInitialValues';

function MultiSelect(props: {
    label: string;
    options: string[];
    setFieldAttribute: string;
    attributeValue: string[];
}) {
    const formik = useFormikContext<EmployeeTypeInitialValues>();
    return (
        <div className=" text-center rounded-lg bg-zedex-button-blue text-white create-order w-48   hover:bg-zedex-button-blue-hover">
            <Listbox
                value={props.attributeValue}
                onChange={(event) => {
                    if (event.length === 0) {
                        formik.setFieldValue(
                            props.setFieldAttribute,
                            props.options
                        );
                    } else {
                        formik.setFieldValue(props.setFieldAttribute, event);
                    }
                }}
                multiple
            >
                <Listbox.Button className="relative p-3 w-full rounded-lg inline-block text-sm space-x-2">
                    <span className="align-middle">{props.label}</span>
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400 inline-block"
                        aria-hidden="true"
                    />
                </Listbox.Button>
                <Listbox.Options className="bg-zedex-button-blue mt-1 rounded-lg z-10 absolute overflow-auto min-w-[15rem] py-2 px-5">
                    {props.options.map((option) => (
                        <Listbox.Option
                            key={option}
                            value={option}
                            className="hover:bg-trev"
                        >
                            {props.attributeValue.includes(option) ? (
                                <CheckIcon className="w-5 h-5 inline-block float-left border rounded-sm" />
                            ) : (
                                <div className="w-5 h-5 inline-block float-left fill-white border rounded-sm bg-white p-1" />
                            )}
                            <span>{option}</span>
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </Listbox>
        </div>
    );
}

export default function EmployeeTypeButton() {
    const formik = useFormikContext<EmployeeTypeInitialValues>();

    return (
        <MultiSelect
            label="Employee Type"
            options={[
                'ADMIN',
                'SEOADMIN',
                'CMSADMIN',
                'CMSEMPLOYEE',
                'SEOEMPLOYEE',
            ]}
            setFieldAttribute="roleFilter"
            attributeValue={formik.values.roleFilter}
        />
    );
}

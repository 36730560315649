import { Formik } from 'formik';
import { CreateContentOrderButton } from '../../components/contentOrderList/CreateOrderButton';
import { Filter } from '../../components/contentOrderList/Filters';
import { PredefinedFilters } from '../../components/contentOrderList/PredefinedFilters';
import { ContentServiceTypeButton } from '../../components/contentOrderList/ServiceTypeButton';
import { TableAndGrid } from '../../components/contentOrderList/TableGrid';
import { FilterIcon } from '../../components/main/FilterIcon';
import NavBar from '../../components/main/Navbar/Navbar';
import { PaginationFooter } from '../../components/main/PaginationFooter';
import PathBar from '../../components/main/PathBar';
import ProfileBar from '../../components/main/ProfileBar';
import { SearchBar } from '../../components/main/SearchBar';

export function ContentOrders() {
    return (
        <>
            <NavBar activeElement="contentOrders" />
            <ProfileBar title="Content Orders" />
            <div className="bg-zedex-bg-gray pt-24 ml-20">
                <PathBar />
                <div className="Client-Order !-ml-0.5">
                    <Formik
                        initialValues={{
                            paginationRowCount: 15,
                            pageNum: 0,
                            totalPages: 1,
                            totalRows: 0,
                            sortBy: 'id',
                            sortType: 'asc',
                            statusFilter: [
                                'Done',
                                'Assigned',
                                'In-Progress',
                                'In-Review',
                            ],
                            filterToggle: false,
                            toggleView: true,
                            search: {
                                contains: '',
                            },
                        }}
                        onSubmit={() => {
                            //
                        }}
                    >
                        <>
                            <div className="mt-3 space-x-4">
                                <SearchBar placeholder="Search using Client Name, Order ID, Client Site URL or keyword" />
                                <FilterIcon />
                                <div className="space-x-5 mt-3">
                                    <CreateContentOrderButton />
                                    <ContentServiceTypeButton />
                                </div>
                            </div>
                            <Filter />
                            <PredefinedFilters />
                            <TableAndGrid />
                            <PaginationFooter />
                        </>
                    </Formik>
                </div>
            </div>
        </>
    );
}

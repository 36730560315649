import { useFormikContext } from 'formik';
import { WebsiteOutreachPoolInitialValues } from '../../types/WebsiteOutreachPoolInitialValues';
import { exportCSV } from '../main/ExportData';

export function ExportCSVButton() {
    const formik = useFormikContext<WebsiteOutreachPoolInitialValues>();
    return (
        <button
            type="button"
            disabled={formik.values.exportData.length === 0}
            className="bg-zedex-button-blue text-white create-order disabled:bg-slate-400 align-middle py-2.5 w-48 hover:bg-zedex-button-blue-hover"
            onClick={() =>
                exportCSV(formik.values.exportData, formik.values.csvHeader)
            }
        >
            <div className="flex flex-row justify-center space-x-2">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-5 h-5 inline-block align-middle self-center"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                </svg>

                <span className="self-center">Export CSV</span>
            </div>
        </button>
    );
}
